import * as React from "react";
import { graphql } from "gatsby";
import {
  FrameBlock50,
  FrameSectionRow,
} from "../../../components/basics/frame/frameBlocks";
import PageContent from "../../../components/layout/content";
import PageHeader from "../../../components/layout/header/pageHeader";
import Layout from "../../../components/layout/layout";
import DisplayIcon from "../../../components/basics/displayIcon/displayIcon";
import { useMemo } from "react";
import ImageDisplay from "../../../components/basics/imageDisplay/imageDisplay";
import ButtonContainer from "../../../components/basics/button/buttonContainer";
import Button from "../../../components/basics/button/button";
import IconButtonWithText from "../../../components/iconButton/iconButtonWithText";
import {
  faFacebook,
  faInstagram,
  faTiktok,
} from "@fortawesome/free-brands-svg-icons";
import "../../../theme/style.scss";
const ContactsPage = ({ data }) => {
  const pageData = useMemo(() => data.contentfulOldal, [data]);
  return (
    <Layout>
      <PageHeader pageData={pageData} />
      <PageContent>
        <FrameSectionRow>
          <FrameBlock50>
            <h3>{pageData?.tartalomTpus?.cm}</h3>
            <p className="text-center">{pageData?.tartalomTpus?.alcm}</p>
            <div>
              <IconButtonWithText
                openOnNewPage
                externalLink
                icon={faInstagram}
                url={
                  pageData?.tartalomTpus?.instagram?.includes("http")
                    ? pageData?.tartalomTpus?.instagram
                    : `https://${pageData?.tartalomTpus?.instagram}`
                }
                text={pageData?.tartalomTpus?.instagram}
              />
              <IconButtonWithText
                openOnNewPage
                externalLink
                icon={faFacebook}
                url={
                  pageData?.tartalomTpus?.email?.includes("http")
                    ? pageData?.tartalomTpus?.email
                    : `https://${pageData?.tartalomTpus?.email}`
                }
                text={pageData?.tartalomTpus?.email}
              />
              <IconButtonWithText
                openOnNewPage
                externalLink
                icon={faTiktok}
                url={
                  pageData?.tartalomTpus?.email?.includes("http")
                    ? pageData?.tartalomTpus?.tiktok
                    : `https://${pageData?.tartalomTpus?.tiktok}`
                }
                text={pageData?.tartalomTpus?.tiktok}
              />
            </div>
          </FrameBlock50>
          <FrameBlock50>
            <ImageDisplay image={pageData?.tartalomTpus?.elsKp} />
          </FrameBlock50>
        </FrameSectionRow>
        <FrameSectionRow reverse>
          <FrameBlock50>
            <h3>{pageData?.tartalomTpus?.msodikCm}</h3>
            <p className="text-center">{pageData?.tartalomTpus?.msodikAlcm}</p>
            <ButtonContainer>
              <Button text="Contact an instructor" url={"/instructors"} />
            </ButtonContainer>
          </FrameBlock50>
          <FrameBlock50>
            <ImageDisplay image={pageData?.tartalomTpus?.msodikKp} />
          </FrameBlock50>
          <DisplayIcon
            imageSvg={
              <svg
                width="431"
                height="370"
                viewBox="0 0 431 370"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M65.5 15H365.5C393.114 15 415.5 37.3858 415.5 65V235.617C415.5 263.232 393.114 285.617 365.5 285.617H329.574C310.483 285.617 295.006 301.094 295.006 320.185V339.416C295.006 347.887 288.14 354.753 279.669 354.753C276.172 354.753 272.78 353.558 270.055 351.365L202.066 296.661C193.183 289.514 182.123 285.617 170.722 285.617H65.5C37.8858 285.617 15.5 263.232 15.5 235.617V65C15.5 37.3858 37.8858 15 65.5 15Z"
                  stroke="#8900AF"
                  stroke-opacity="0.4"
                  stroke-width="30"
                />
              </svg>
            }
          />
        </FrameSectionRow>
      </PageContent>
    </Layout>
  );
};

export const query = graphql`
  query ($contentfulId: String) {
    contentfulOldal(contentful_id: { eq: $contentfulId }) {
      slug
      cm
      alcm
      kategria {
        slug
      }
      tartalomTpus {
        ... on ContentfulElerhetosegeinkTartalomTipus {
          id
          header {
            bortkp {
              url
              gatsbyImageData(layout: CONSTRAINED)
            }
            contentful_id
            lers
            title
            primaryCta {
              ... on ContentfulCtaGomb {
                ctaGombLink {
                  ... on ContentfulOldal {
                    slug
                    kategria {
                      slug
                    }
                  }
                  ... on ContentfulOldalKategoria {
                    slug
                  }
                }
                ctaGombSzveg
                externalCtaGombLink
              }
            }
            secondaryCta {
              ... on ContentfulCtaGomb {
                ctaGombLink {
                  ... on ContentfulOldal {
                    slug
                    kategria {
                      slug
                    }
                  }
                  ... on ContentfulOldalKategoria {
                    slug
                  }
                }
                ctaGombSzveg
                externalCtaGombLink
              }
            }
          }
          elsKp {
            gatsbyImageData(layout: CONSTRAINED)
          }
          cm
          alcm
          email
          tiktok
          instagram
          msodikCm
          msodikKp {
            gatsbyImageData(layout: CONSTRAINED)
          }
          msodikAlcm
        }
      }
    }
  }
`;
export default ContactsPage;
