import * as React from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./iconButton.scss";
import "../basics/button/button.scss";
import InvisibleButton from "../basics/button/invisibleButton";

const IconButtonWithText = ({
  text,
  url,
  externalLink: isExternalLink = false,
  onClick,
  submitButton: isSubmit = false,
  openOnNewPage = false,
  icon,
}) => {
  if (url) {
    if (isExternalLink) {
      return (
        <a
          href={url}
          className="icon-button-with-text"
          target={openOnNewPage && "_blank"}
        >
          <div className={`button block-button icon-button`}>
            <span>
              <FontAwesomeIcon className="button-icon-centered" icon={icon} />
            </span>
          </div>
          <p>{text}</p>
        </a>
      );
    }
    return (
      <Link className="icon-button-with-text" to={url}>
        <div className={`button block-button icon-button`}>
          <span>
            <FontAwesomeIcon className="button-icon-centered" icon={icon} />
          </span>
        </div>
        <p>{text}</p>
      </Link>
    );
  }

  return (
    <InvisibleButton
      onClick={onClick}
      className="icon-button-with-text"
      type={isSubmit ? "submit" : "button"}
    >
      <div className={`button block-button icon-button`}>
        <span>
          <FontAwesomeIcon className="button-icon-centered" icon={icon} />
        </span>
        <p>{text}</p>
      </div>
    </InvisibleButton>
  );
};
export default IconButtonWithText;
IconButtonWithText.propTypes = {
  externalLink: PropTypes.bool,
  link: PropTypes.string,
  onClick: PropTypes.func,
  submitButton: PropTypes.bool,
  openOnNewPage: PropTypes.bool,
  icon: PropTypes.object.isRequired,
  url: PropTypes.string,
  text: PropTypes.string,
};
