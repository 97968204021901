import * as React from "react";
import useDisplayIcon from "../../../core/hooks/useDisplayIcon";
import PropTypes from "prop-types";
import "./displayIcon.scss";
/**
 * Custom component for displaying animated line svg
 *
 * @param {boolean} defaultCalculate - This value sets if it should check for visibilityChange.
 * @param {object} imageSvg - Svg component to display
 * @param {string} className - classnames for the component
 * @param {object} reverse - Changes the location of the icon between left/right side (Default is false, left)
 * @param {string} size - set this according to the stroke length of the svg
 */

const types = {
  small: "small",
  large: "large",
};

const DisplayIcon = ({
  defaultCalculate = true,
  imageSvg,
  reverse = false,
  className = "",
  size = "small",
}) => {
  const [display, ref] = useDisplayIcon(defaultCalculate);

  if (reverse) {
    return (
      <div
        ref={ref}
        className={
          display
            ? `row-icon-reverse ${className} ${types[size]} display`
            : `row-icon-reverse ${className}`
        }
      >
        {imageSvg}
      </div>
    );
  } else {
    return (
      <div
        ref={ref}
        className={
          display
            ? `row-icon ${className} ${types[size]} display`
            : `row-icon ${className}`
        }
      >
        {imageSvg}
      </div>
    );
  }
};

export default DisplayIcon;

DisplayIcon.propTypes = {
  defaultCalculate: PropTypes.bool,
  imageSvg: PropTypes.node.isRequired,
  reverse: PropTypes.bool,
  size: PropTypes.string,
};
